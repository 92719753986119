import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import emailIsValid from '../../components/EmailValidator'
import FormOverlay from '../../components/FormOverlay'
import { Analytics } from '../../context/Analytics'
import { User } from '../../context/User'
//import { Theme } from '../../context/Theme'
import './styles.css'

const axios = require('axios')

const Verify = (props) => {
  const analytics = Analytics()
  const user = User()
  //const theme = Theme()

  const [isValid, setIsValid] = useState(0)
  const [formError, setFormError] = useState(false)
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    //theme.hideHeader()
    //theme.hideFooter()

    return () => {
      analytics.endEvent()
      //theme.displayHeader()
      //theme.displayFooter()
    }
  }, [])

  useEffect(() => {
    let location = props.history.location
    let paramsList = location.search
    let valid
    let token
    if (typeof paramsList !== 'undefined' || paramsList !== '') {
      paramsList = paramsList.replace('?', '')
      paramsList = paramsList.split('&')
      paramsList.forEach(query => {
        const item = query.split('=')
        if (item[0] === 'token') {
          try {
            token = item.slice(1).join('=')
            let decoded = atob(token)
            decoded = decoded.split('.')
            const UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
            let validUUID = UUID.test(decoded[0])
            let email = decoded.slice(1).join('.')
            let validEmail = emailIsValid(email)
            valid = (validUUID && validEmail)
            setIsValid(valid)
            if (valid) {
              user.login(token)
            } else {
              setFormError(true)
            }
          } catch (err) {
            analytics.startSingleEvent({
              href: window.location.href,
              msg: err.message,
              type: 'error',
            })
            setFormError(true)
          }
        }
      })
      if (typeof token === 'undefined') {
        analytics.startSingleEvent({
          href: window.location.href,
          msg: 'No token provided',
          type: 'error',
        })
        setRedirect(true)
      }
      delete location.search
      props.history.push(location)
    } else {
      analytics.startSingleEvent({
        href: window.location.href,
        msg: 'No token provided',
        type: 'error',
      })
      setRedirect(true)
    }
  }, [])

  return (
    (isValid === true || redirect === true) ? <Redirect to="/" /> :
    <div className={"verify-page fullpage"}>
    <FormOverlay
      open={true}
      formError={formError}
      loadingMessageShort={"Loading"}
      loadingMessageLong={"Reading your magic link"}
      errorMessageTitle={"Oh no! Your magic link is no longer valid."}
      errorMessageSub={"Request a new one to try again."}
      errorMessageSubSmall={"We'll automatically redirect you in"}
      shouldRefresh={formError}
      refreshIn={10}
      >
      <p className="error-message pretty-fade">
        <svg className="envelope" width="524" height="404" viewBox="0 0 524 404" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="envelope 1">
            <g clipPath="url(#clip0)">
              <g id="layer1">
                <g id="env-shadow">
                  <path id="rect833-7" opacity="0.1" d="M520.072 107.978H68.6432V402.264H520.072V107.978Z" fill="black" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g id="env">
                  <path id="rect833" d="M522.072 103.978H70.6432V398.264H522.072V103.978Z" fill="white" stroke="#F2F3F4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  <path id="path837" d="M116.73 362.609L379.26 159.633" stroke="#F2F3F4" strokeWidth="2" strokeLinecap="round"/>
                  <path id="path837-3" d="M476.403 362.931L213.455 159.634" stroke="#F2F3F4" strokeWidth="2" strokeLinecap="round"/>
                  <g id="path858">
                    <path d="M96.3575 136.835L274.929 258.264C274.929 258.264 294.929 272.55 317.786 258.264C340.643 243.978 489.215 141.121 494.929 133.978" fill="white"/>
                    <path d="M96.3575 136.835L274.929 258.264C274.929 258.264 294.929 272.55 317.786 258.264C340.643 243.978 489.215 141.121 494.929 133.978" stroke="#F2F3F4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  </g>
                  <g id="EXPIRED">
                    <path d="M131.287 228.096H165.079V233.408H137.367V246.912H162.263V252.224H137.367V266.688H165.463V272H131.287V228.096Z" fill="#FF0000" fillOpacity="0.74"/>
                    <path d="M196.119 249.024L180.375 228.096H187.927L199.575 243.776H200.087L211.863 228.096H219.159L203.543 248.96L220.887 272H213.399L200.023 254.016H199.511L186.199 272H178.839L196.119 249.024Z" fill="#FF0000" fillOpacity="0.74"/>
                    <path d="M235.862 228.096H257.238C261.633 228.096 265.004 229.312 267.35 231.744C269.697 234.176 270.87 237.419 270.87 241.472C270.87 245.611 269.633 248.939 267.158 251.456C264.726 253.931 261.42 255.168 257.238 255.168H241.942V272H235.862V228.096ZM256.918 249.856C259.308 249.856 261.206 249.109 262.614 247.616C264.022 246.123 264.726 244.096 264.726 241.536C264.726 238.933 264.065 236.928 262.742 235.52C261.42 234.112 259.478 233.408 256.918 233.408H241.942V249.856H256.918Z" fill="#FF0000" fillOpacity="0.74"/>
                    <path d="M288.982 228.096H295.062V272H288.982V228.096Z" fill="#FF0000" fillOpacity="0.74"/>
                    <path d="M315.562 228.096H338.41C342.805 228.096 346.176 229.227 348.522 231.488C350.869 233.749 352.042 236.779 352.042 240.576C352.042 243.563 351.317 246.144 349.866 248.32C348.458 250.496 346.496 252.053 343.978 252.992L354.09 272H347.306L337.77 253.888H321.642V272H315.562V228.096ZM338.09 248.576C340.48 248.576 342.378 247.872 343.786 246.464C345.194 245.056 345.898 243.115 345.898 240.64C345.898 238.336 345.237 236.565 343.914 235.328C342.592 234.048 340.65 233.408 338.09 233.408H321.642V248.576H338.09Z" fill="#FF0000" fillOpacity="0.74"/>
                    <path d="M371.35 228.096H405.142V233.408H377.43V246.912H402.326V252.224H377.43V266.688H405.526V272H371.35V228.096Z" fill="#FF0000" fillOpacity="0.74"/>
                    <path d="M423.637 228.096H439.893C446.933 228.096 452.352 229.909 456.149 233.536C459.947 237.12 461.845 242.624 461.845 250.048C461.845 257.472 459.947 262.997 456.149 266.624C452.352 270.208 446.933 272 439.893 272H423.637V228.096ZM439.893 266.688C444.971 266.688 448.832 265.408 451.477 262.848C454.165 260.288 455.531 256.064 455.573 250.176V250.048C455.573 244.117 454.229 239.872 451.541 237.312C448.896 234.709 445.013 233.408 439.893 233.408H429.717V266.688H439.893Z" fill="#FF0000" fillOpacity="0.74"/>
                  </g>
                  <rect id="Rectangle 1" x="120.5" y="217.5" width="350" height="65" stroke="#FF0000" strokeOpacity="0.74" strokeWidth="3"/>
                </g>
                <g id="star-two-shadow">
                  <path id="path893-4" opacity="0.1" d="M37.2471 142.048C31.6219 144.376 27.066 131.652 21.4425 129.321C15.819 126.989 3.59612 132.756 1.26845 127.13C-1.05922 121.505 11.6647 116.949 13.9964 111.326C16.3281 105.702 10.5612 93.4795 16.1864 91.1518C21.8116 88.8241 26.3676 101.548 31.9911 103.88C37.6146 106.211 49.8374 100.445 52.1651 106.07C54.4928 111.695 41.7689 116.251 39.4372 121.874C37.1055 127.498 42.8723 139.721 37.2471 142.048V142.048Z" fill="black" stroke="black" strokeOpacity="0.995951" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path id="path893-5-1" opacity="0.1" d="M129.628 77.8303C125.917 79.3659 122.912 70.9717 119.202 69.4335C115.492 67.8952 107.428 71.6997 105.893 67.9887C104.357 64.2777 112.751 61.2721 114.29 57.5622C115.828 53.8523 112.023 45.7887 115.734 44.2531C119.445 42.7175 122.451 51.1116 126.161 52.6499C129.871 54.1882 137.934 50.3837 139.47 54.0947C141.006 57.8057 132.611 60.8113 131.073 64.5212C129.535 68.2311 133.339 76.2947 129.628 77.8303Z" fill="black" stroke="black" strokeOpacity="0.995951" strokeWidth="1.31943" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g id="star-one-shadow">
                  <path id="path893-5-8-6" opacity="0.1" d="M47.8698 42.331C52.2189 44.1306 55.7413 34.2933 60.089 32.4905C64.4368 30.6878 73.8868 35.1464 75.6864 30.7973C77.486 26.4482 67.6486 22.9259 65.8459 18.5781C64.0432 14.2303 68.5018 4.78031 64.1527 2.98069C59.8036 1.18107 56.2812 11.0184 51.9335 12.8212C47.5857 14.6239 38.1357 10.1653 36.3361 14.5144C34.5365 18.8635 44.3739 22.3859 46.1766 26.7337C47.9793 31.0814 43.5207 40.5314 47.8698 42.331V42.331Z" fill="black" stroke="black" strokeOpacity="0.995951" strokeWidth="1.54629" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g id="star-two">
                  <path id="path893" d="M37.2471 140.048C31.6219 142.376 27.066 129.652 21.4425 127.32C15.819 124.989 3.59612 130.756 1.26845 125.13C-1.05922 119.505 11.6647 114.949 13.9964 109.326C16.3281 103.702 10.5612 91.4794 16.1864 89.1517C21.8116 86.824 26.3676 99.548 31.9911 101.88C37.6146 104.211 49.8374 98.4445 52.1651 104.07C54.4928 109.695 41.7689 114.251 39.4372 119.874C37.1055 125.498 42.8723 137.721 37.2471 140.048Z" fill="#F0C808" stroke="#F0C808" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path id="path893-5" d="M129.628 75.8302C125.917 77.3658 122.912 68.9717 119.202 67.4335C115.492 65.8952 107.428 69.6997 105.893 65.9887C104.357 62.2776 112.751 59.2721 114.29 55.5621C115.828 51.8522 112.023 43.7887 115.734 42.2531C119.445 40.7175 122.451 49.1116 126.161 50.6499C129.871 52.1881 137.934 48.3837 139.47 52.0947C141.006 55.8057 132.611 58.8113 131.073 62.5212C129.535 66.2311 133.339 74.2947 129.628 75.8302Z" fill="#F0C808" stroke="#F0C808" strokeWidth="1.31943" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g id="star-one">
                  <path id="path893-5-8" d="M47.8698 40.331C52.2189 42.1306 55.7413 32.2933 60.089 30.4905C64.4368 28.6878 73.8868 33.1464 75.6864 28.7973C77.486 24.4482 67.6487 20.9259 65.8459 16.5781C64.0432 12.2303 68.5018 2.78031 64.1527 0.980693C59.8036 -0.818925 56.2812 9.01844 51.9335 10.8212C47.5857 12.6239 38.1357 8.16533 36.3361 12.5144C34.5365 16.8635 44.3739 20.3858 46.1766 24.7336C47.9793 29.0814 43.5207 38.5314 47.8698 40.331V40.331Z" fill="#F0C808" stroke="#F0C808" strokeWidth="1.54629" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
              </g>
            </g>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="523.572" height="403.764" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </p>
    </FormOverlay>
    </div>
  )
}

export default Verify
