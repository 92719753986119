import React, { useState, useEffect } from 'react'
import { Consent } from '../../context/Consent'
//import { Theme } from '../../context/Theme'
import './styles.css'

const Footer = (props) => {
  const consent = Consent()
  //const theme = Theme()

  const [showFooter, setShowFooter] = useState(true)

  /*
  useEffect(() => {
    setShowFooter(theme.showFooter)
  }, [theme])
  */

  return <footer className={showFooter ? "footer" : "footer hidden"} id="footer">
    <p className="copyright">&copy; 2019 <a href="https://eciso.io" title="eCISO - Security for Startups">eciso, llc.</a> &bull; <a href="#cookies" onClick={consent.toggleNotice} id="cookie-preferences" title="Manage your cookie preferences" className="cookie-button">cookies</a> &bull; <a href="https://ciso.dev/terms" title="Read our Terms of Service">terms</a> &bull; <a href="https://ciso.dev/privacy" title="Read our Privacy Policy">privacy</a></p>
  </footer>
}

export default Footer
