import React, { useState, useEffect } from 'react'
import { User } from '../../context/User'
import './styles.css'

const Toast = (props) => {
  const [open, setOpen] = useState(props.open)

  let timer
  let toastTimer = {
    time: {},
    seconds: 5,
  }

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60))
    let divisor_for_minutes = secs % (60 * 60)
    let minutes = Math.floor(divisor_for_minutes / 60)
    let divisor_for_seconds = divisor_for_minutes % 60
    let seconds = Math.ceil(divisor_for_seconds)
    let obj = {
      'h': hours,
      'm': minutes,
      's': seconds,
    }
    return obj
  }

  const toastCountdown = () => {
    const seconds = toastTimer.seconds - 1
    if (seconds === 0) {
      clearInterval(timer)
      props.close()
    }
    toastTimer = {
      time: secondsToTime(seconds),
      seconds: seconds,
    }
  }

  const toastTimerStart = () => {
    timer = setInterval(toastCountdown, 1000)
  }

  useEffect(() => {
    setOpen(props.open)
  }, [props])

  useEffect(() => {
    if (open) toastTimerStart()
  }, [open])

  //const user = User()

  //const [open, setOpen] = useState(user.showToast || false)
  //const [toastError, setToastError] = useState(user.toastError || false)
  //const [toastSuccess, setToastSuccess] = useState(user.toastSuccess || false)

  /*
  const toastClose = (e) => {
    e.preventDefault()
    user.closeToast()
  }
  */

  /*
  useEffect(() => {
    setOpen(user.showToast)
    setToastError(user.toastError)
    setToastSuccess(user.toastSuccess)
  }, [user])
  */

  return <div className={"toast" + (props.open ? " open" : "") + (props.toastError ? " error" : "") + (props.toastSuccess ? " success" : "")}>
    <div className="toast-wrapper">
      <button className="toast-close" onClick={props.close}>
        <svg role="img" aria-label="Close" width="12" height="12" viewport="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <title>Close</title>
          <line x1="1" y1="11" x2="11" y2="1" strokeWidth="1"></line>
          <line x1="1" y1="1" x2="11" y2="11" strokeWidth="1"></line>
        </svg>
      </button>
      <div className="toast-message">
        <h3>{props.toastTitle}</h3>
        <p>{props.toastMessage}</p>
      </div>
    </div>
  </div>
}

export default Toast
