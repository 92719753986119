import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Analytics } from '../../context/Analytics'
import { User } from '../../context/User'
//import { Theme } from '../../context/Theme'
import './styles.css'

const Header = (props) => {
  const analytics = Analytics()
  const user = User()
  //const theme = Theme()

  const didMountRef = useRef(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [signedIn, setSignedIn] = useState(user.signedIn || false)
  const [account, setAccount] = useState(user.account() || {})
  const [meta, setMeta] = useState(user.meta() || {})
  //const [showHeader, setShowHeader] = useState(theme.showHeader)
  const [showHeader, setShowHeader] = useState(true)

  const menuOnClick = (e) => {
    e.preventDefault()
    setMenuOpen(!menuOpen)
  }

  const menuClose = () => {
    setMenuOpen(false)
  }

  useEffect(() => {
    setSignedIn(user.signedIn)
    setAccount(user.account())
    setMeta(user.meta())
  }, [user])

  /*
  useEffect(() => {
    setShowHeader(theme.showHeader)
  }, [theme])
  */

  useEffect(() => {
    setTimeout(() => {
      if (menuOpen) {
        window.addEventListener('click', menuClose)
      } else {
        window.removeEventListener('click', menuClose)
      }
    }, 0)

    return () => {
      window.removeEventListener('click', menuClose)
    }
  })

  return <header className={`${signedIn ? "header authed" : "header"} ${!showHeader ? "none" : ""}`} id="header">
    <div onClick={menuOnClick} className="hamburger-wrapper">
      <div className={"hamburger-menu" + (menuOpen ? " open" : " closed")}>
        <div className="menu-bars" id="menu-bars">
          <span className="bar bar1"></span>
          <span className="bar bar2"></span>
          <span className="bar bar3"></span>
        </div>
      </div>
    </div>
    <div className="logo-wrapper">
      <div className="logo-inner">
        {!signedIn
          ? <a href="https://ciso.dev" title="CISO.DEV - Mentoring for security professionals" className="logo">CISO.DEV</a>
          : ((meta.isMentor && account.mentor !== "")
            ? <Link to="/" title="CISO.DEV" className="logo">CISO.DEV</Link>
            : <Link to="/mentees" title="CISO.DEV" className="logo">CISO.DEV</Link>
          )
        }
      </div>
    </div>
    <div className="cta-wrapper">
      <Link to="/logout" title="Sign out of your account" className="primary">Sign out</Link>
    </div>
    <div className="nav-background"></div>
    <div className={"menu" + (menuOpen ? " open" : " closed")}>
      <ul className="nav">
        {meta.isAdmin
          ? <li><Link to="/mentors" onClick={menuClose} title="Manage our mentor accounts">Manage mentors</Link></li>
          : <></>
        }
        {((!meta.isMentor) || (account.mentor !== ""))
          ? <li><Link to="/" onClick={menuClose} title="Connect with your security mentor">Connect with your mentor</Link></li>
          : <></>
        }
        {meta.isMentor
          ? <li><Link to="/mentees" onClick={menuClose} title="Manage your mentees">Manage your mentees</Link></li>
          : <></>
        }
        <li><Link to="/settings" onClick={menuClose} title="Update your account settings">Account settings</Link></li>
        <li><Link to="/logout" onClick={menuClose} title="Sign out of your account" className="cta">Sign out</Link></li>
      </ul>
    </div>
  </header>
}

export default Header
