import React, { useState, useEffect } from 'react'
import AuthLoader from '../components/AuthLoader'
import FormOverlay from '../components/FormOverlay'
import Toast from '../components/Toast'
import { useCookies } from 'react-cookie'

const axios = require('axios')
axios.defaults.withCredentials = true

const UserContext = React.createContext()

const UserProvider = (props) => {
  // Set authentication state values
  const [cookies, setCookies, removeCookies] = useCookies(['Session'])
  const [signedIn, setSignedIn] = useState(false)
  const [loading, setLoading] = useState(true)

  // Set default values for toast messages
  const [showToast, setShowToast] = useState(false)
  const [toastError, setToastError] = useState(false)
  const [toastSuccess, setToastSuccess] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastTitle, setToastTitle] = useState('')

  // Set default values for form overlay
  const [formTriggered, setFormTriggered] = useState(false)
  const [formError, setFormError] = useState(false)

  // Set user data state values
  const [createdAt, setCreatedAt] = useState(localStorage.getItem('createdAt') || 0)
  const [updatedAt, setUpdatedAt] = useState(localStorage.getItem('updatedAt') || 0)
  const [consent, setConsent] = useState(localStorage.getItem('consent') || 0)
  const [current, setCurrent] = useState(localStorage.getItem('current') === "true" || false)
  const [onboard, setOnboard] = useState(localStorage.getItem('onboard') === "true" || false)
  const [welcome, setWelcome] = useState(localStorage.getItem('welcome') || '')
  const [track, setTrack] = useState(localStorage.getItem('track') || 1)
  const [hours, setHours] = useState(localStorage.getItem('hours') || 1)
  const [corp, setCorp] = useState(localStorage.getItem('corp') === "true" || false)
  const [cost, setCost] = useState(localStorage.getItem('cost') || 49)
  const [lastLogin, setLastLogin] = useState({
    time: localStorage.getItem('lastLoginTime') || 0,
    ip: localStorage.getItem('lastLoginIP') || '',
  })
  const [email, setEmail] = useState(localStorage.getItem('email') || '')
  const [name, setName] = useState(localStorage.getItem('name') || '')
  const [linkedin, setLinkedin] = useState(localStorage.getItem('linkedin') || '')
  const [mentor, setMentor] = useState(localStorage.getItem('mentor') || '')
  const [mentees, setMentees] = useState(localStorage.getItem('mentees') || [])
  const [notes, setNotes] = useState(localStorage.getItem('notes') || [])
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin') === "true" || false)
  const [isMentor, setIsMentor] = useState(localStorage.getItem('isMentor') === "true" || false)

  const [appUsers, setAppUsers] = useState([])

  // Close toast modal
  const closeToast = () => {
    setShowToast(false)
    setToastError(false)
    setToastSuccess(false)
    setToastTitle('')
    setToastMessage('')
  }

  // Return user account metadata
  const meta = () => {
    return {
      createdAt: createdAt,
      updatedAt: updatedAt,
      lastLogin: lastLogin,
      isMentor: isMentor,
      isAdmin: isAdmin,
      consent: consent,
      current: current,
    }
  }

  // Return basic account details
  const account = () => {
    return {
      welcome: welcome,
      mentees: mentees,
      track: track,
      hours: hours,
      mentor: mentor,
      notes: notes,
      corp: corp,
      cost: cost,
    }
  }

  // Return user privileges
  const privs = () => {
    return {
      isAdmin: isAdmin,
      isMentor: isMentor,
    }
  }

  // Return collection of users
  const getUsers = async (body) => {
    try {
      return await axios.post('http://localhost:4000/v1/users', {
        type: body.type,
        range: body.range,
      }).then((res) => {
        setAppUsers(res.data.data)
      }).catch((err) => {
        throw err
      })
    } catch (err) {
      setToastTitle("An error occurred!")
      setToastMessage("We were unable to connect to the API, please try again.")
      setToastError(true)
      setShowToast(true)
    }
  }

  // Update the user's billing info
  const updateBilling = async () => {
    // Initialize Stripe
    let stripe
    try {
      stripe = window.Stripe(`${process.env.REACT_APP_STRIPE_PUBKEY}`)
    } catch (err) {
      setToastTitle("An error occurred!")
      setToastMessage("We were unable to connect to Stripe, please try again.")
      setToastError(true)
      setShowToast(true)
      return
    }
    // Retrieve a Stripe checkout session
    try {
      axios.post('http://localhost:4000/v1/subscribe/update', {}).then((res) => {
        // Redirect to Stripe
        stripe.redirectToCheckout({
          sessionId: res.data.session,
        }).then((result) => {
        }).catch((err) => {
          throw err
        })
      }).catch((err) => {
        throw err
      })
    } catch (err) {
      // Show toast
      setToastTitle("An error occurred!")
      setToastMessage("We were unable to connect to Stripe, please try again.")
      setToastError(true)
      setShowToast(true)
    }
  }

  // Cancel the user's subscription
  const cancelSubscription = async () => {
    await axios.post('http://localhost:4000/v1/user/cancel', {
      email: email,
    }).then((res) => {
      // Show confirmation overlay
      //setCurrent(false)
      setFormError(true)
      setFormTriggered(true)
    }).catch((err) => {
      // Show toast modal on errors
      setToastTitle("An error occurred!")
      setToastMessage("We couldn't update your account, please try again.")
      setToastError(true)
      setShowToast(true)
    })
  }

  // Update user state data
  const updateUserData = async (data, save) => {
    let payload = {}
    if (data.email) {
      setEmail(data.email)
    }
    if (data.createdAt) {
      setCreatedAt(data.createdAt)
    }
    if (data.updatedAt) {
      setUpdatedAt(data.updatedAt)
    }
    if (data.current === true || data.current === false) {
      setCurrent(data.current)
    }
    if (data.consent) {
      setConsent(data.consent)
    }
    if (data.onboard === true || data.onboard === false) {
      payload.onboard = data.onboard
      setOnboard(data.onboard)
    }
    if (data.track) {
      setTrack(data.track)
    }
    if (data.hours) {
      setHours(data.hours)
    }
    if (data.corp === true || data.corp === false) {
      setCorp(data.corp)
    }
    if (data.cost) {
      setCost(data.cost)
    }
    if (data.lastLogin) {
      setLastLogin(data.lastLogin)
    }
    if (data.name) {
      payload.name = data.name
      setName(data.name)
    }
    if (data.linkedin) {
      payload.linkedin = data.linkedin
      setLinkedin(data.linkedin)
    }
    if (data.mentor) {
      setMentor(data.mentor)
    }
    if (data.mentees) {
      setMentees(data.mentees)
    }
    if (data.notes) {
      setNotes(data.notes)
    }
    if (data.welcome) {
      setWelcome(data.welcome)
    }
    if (data.isAdmin === true || data.isAdmin === false) {
      setIsAdmin(data.isAdmin)
    }
    if (data.isMentor === true || data.isMentor === false) {
      setIsMentor(data.isMentor)
    }
    if (save) {
      await axios.post('http://localhost:4000/v1/user', payload).then((res) => {
        setToastTitle("Account updated!")
        setToastMessage("Thanks, we've updated your account.")
        setToastSuccess(true)
        setShowToast(true)
        // Toast message
      }).catch((err) => {
        setToastTitle("An error occurred!")
        setToastMessage("We couldn't update your account, please try again.")
        setToastError(true)
        setShowToast(true)
        // Toast message
      })
    }
  }

  // Store user data locally
  useEffect(() => {
    localStorage.setItem('email', email)
  }, [email])

  useEffect(() => {
    localStorage.setItem('createdAt', createdAt)
  }, [createdAt])

  useEffect(() => {
    localStorage.setItem('updatedAt', updatedAt)
  }, [updatedAt])

  useEffect(() => {
    localStorage.setItem('current', current)
  }, [current])

  useEffect(() => {
    localStorage.setItem('consent', consent)
  }, [consent])

  useEffect(() => {
    localStorage.setItem('onboard', onboard)
  }, [onboard])

  useEffect(() => {
    localStorage.setItem('welcome', welcome)
  }, [welcome])

  useEffect(() => {
    localStorage.setItem('track', track)
  }, [track])

  useEffect(() => {
    localStorage.setItem('hours', hours)
  }, [hours])

  useEffect(() => {
    localStorage.setItem('corp', corp)
  }, [corp])

  useEffect(() => {
    localStorage.setItem('cost', cost)
  }, [cost])

  useEffect(() => {
    localStorage.setItem('lastLoginTime', lastLogin.time)
    localStorage.setItem('lastLoginIP', lastLogin.ip)
  }, [lastLogin])

  useEffect(() => {
    localStorage.setItem('name', name)
  }, [name])

  useEffect(() => {
    localStorage.setItem('linkedin', linkedin)
  }, [linkedin])

  useEffect(() => {
    localStorage.setItem('mentor', mentor)
  }, [mentor])

  useEffect(() => {
    localStorage.setItem('mentees', mentees)
  }, [mentees])

  useEffect(() => {
    localStorage.setItem('notes', notes)
  }, [notes])

  useEffect(() => {
    localStorage.setItem('isAdmin', isAdmin)
  }, [isAdmin])

  useEffect(() => {
    localStorage.setItem('isMentor', isMentor)
  }, [isMentor])

  // Sign in to the user's account or refresh a JWT
  const login = (code) => {
    if (code) {
      setLoading(true)
      axios.post('http://localhost:4000/v1/auth/verify', {
        code: code,
      }).then((res) => {
        // Store user data in browser
        updateUserData(res.data.data)
        // Set the session cookie with nonce
        let expiry = new Date()
        expiry.setDate(expiry.getDate() + 14)
        let options = {
          path: '/',
          expires: expiry,
          maxAge: (60*60*24*14),
        }
        if (process.env.NODE_ENV === 'production') {
          options.secure = true
          options.domain = `.${window.location.hostname}`
          options.sameSite = true
        }
        setCookies('Session', res.data.nonce, options)
        setSignedIn(true)
        setLoading(false)
      }).catch((err) => {
        setSignedIn(false)
        setLoading(false)
      })
    } else if (cookies.Session) {
      setLoading(true)
      axios.get('http://localhost:4000/v1/auth/validate', {
        withCredentials: true,
      }).then((res) => {
        // Store the user data in the browser
        updateUserData(res.data.data)
        setSignedIn(true)
        setLoading(false)
      }).catch((err) => {
        setSignedIn(false)
        setLoading(false)
      })
    } else {
      setSignedIn(false)
      setLoading(false)
    }
  }

  // End the current session
  const logout = () => {
    setLoading(true)
    axios.get('http://localhost:4000/v1/auth/logout')
    removeCookies('Session')
    localStorage.clear()
    setSignedIn(false)
    setLoading(false)
  }

  // Try to login when the app first loads
  useEffect(() => {
    login()
  }, [])

  return <UserContext.Provider value={{
    signedIn,
    login,
    logout,
    loading,
    getUsers,
    appUsers,
    updateUserData,
    cancelSubscription,
    updateBilling,
    email,
    name,
    linkedin,
    onboard,
    current,
    meta,
    privs,
    account,
    }}>
    {props.children}
    <AuthLoader loading={loading} />
    <FormOverlay
      open={formTriggered}
      formError={formError}
      errorMessageTitle={"We're sorry to see you go..."}
      errorMessageSub={"But we hope you'll be back soon! Check your email to cancel your account."}
      shouldRefresh={false}
      />
    <Toast open={showToast} close={closeToast} toastSuccess={toastSuccess} toastError={toastError} toastTitle={toastTitle} toastMessage={toastMessage} />
  </UserContext.Provider>
}

const User = () => {
  const context = React.useContext(UserContext)
  if (typeof context === 'undefined') {
    throw new Error('User must be used within an UserProvider')
  }
  return context
}

export { UserProvider, User }
