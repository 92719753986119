import React, { useState, useEffect } from 'react'

const FormOverlay = (props) => {
  const [open, setOpen] = useState(props.open || false)
  const [formError, setFormError] = useState(props.formError || false)
  const [formSuccess, setFormSuccess] = useState(props.formSuccess || false)
  const [loadingMessageShort, setLoadingMessageShort] = useState(props.loadingMessageShort || 'Loading')
  const [loadingMessageLong, setLoadingMessageLong] = useState(props.loadingMessageLong || 'Loading')
  const [errorMessageTitle, setErrorMessageTitle] = useState(props.errorMessageTitle || 'Oops, an error occurred!')
  const [errorMessageSub, setErrorMessageSub] = useState(props.errorMessageSub || 'Please refresh the page and try again.')
  const [errorMessageSubSmall, setErrorMessageSubSmall] = useState(props.errorMessageSubSmall || "We'll automatically refresh this page in")
  const [successMessageTitle, setSuccessMessageTitle] = useState(props.successMessageTitle || '')
  const [successMessageSub, setSuccessMessageSub] = useState(props.successMessageSub || '')
  const [successMessageSubSmall, setSuccessMessageSubSmall] = useState(props.successMessageSubSmall || "This page will automatically refresh in")

  const [shouldRefresh, setShouldRefresh] = useState(props.shouldRefresh || false)
  const [refreshIn, setRefreshIn] = useState(props.refreshIn || 5)

  useEffect(() => {
    if (typeof props.open !== 'undefined' && props.open !== open) setOpen(props.open)
  }, [props])

  useEffect(() => {
    if (typeof props.formError !== 'undefined' && props.formError !== formError) setFormError(props.formError)
  }, [props])

  useEffect(() => {
    if (typeof props.formSuccess !== 'undefined' && props.formSuccess !== formSuccess) setFormSuccess(props.formSuccess)
  }, [props])

  useEffect(() => {
    if (typeof props.shouldRefresh !== 'undefined' && props.shouldRefresh !== shouldRefresh) setShouldRefresh(props.shouldRefresh)
  }, [props])

  useEffect(() => {
    if (shouldRefresh) refreshTimerStart()
  }, [shouldRefresh])

  let timer
  let refreshTimer = {
    time: {},
    seconds: props.refreshIn || 5,
  }

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60))
    let divisor_for_minutes = secs % (60 * 60)
    let minutes = Math.floor(divisor_for_minutes / 60)
    let divisor_for_seconds = divisor_for_minutes % 60
    let seconds = Math.ceil(divisor_for_seconds)
    let obj = {
      'h': hours,
      'm': minutes,
      's': seconds,
    }
    return obj
  }

  const refreshCountdown = () => {
    const seconds = refreshTimer.seconds - 1
    if (seconds === 0) {
      clearInterval(timer)
      window.location.reload()
    }
    refreshTimer = {
      time: secondsToTime(seconds),
      seconds: seconds,
    }
    setRefreshIn(seconds)
  }

  const refreshTimerStart = () => {
    timer = setInterval(refreshCountdown, 1000)
  }

  return <div className={"form-submit-overlay" + (open ? " open" : "") + (formError ? " error" : "") + (formSuccess ? " success" : "")}>
    <p className="loading mobile pretty-fade">{loadingMessageShort}<span className="dot-wrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>
    <p className="loading wide pretty-fade">{loadingMessageLong}<span className="dot-wrap"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></span></p>

    {props.children}

    <p className="error-message pretty-fade">{errorMessageTitle}</p>
    <p className="error-message pretty-fade sub">{errorMessageSub}</p>
    <p className={"error-message pretty-fade sub small" + (shouldRefresh ? "" : " hidden")}>({errorMessageSubSmall} {refreshIn}...)</p>

    <p className="success-message pretty-fade ">{successMessageTitle}</p>
    <p className="success-message pretty-fade sub">{successMessageSub}</p>
    <p className={"success-message pretty-fade sub small" + (shouldRefresh ? "" : " hidden")}>({successMessageSubSmall} {refreshIn}...)</p>
  </div>
}

export default FormOverlay
