import React from 'react'
import { AnalyticsProvider } from './Analytics'
import { UserProvider } from './User'
import { AuthProvider } from './Auth'
import { ThemeProvider } from './Theme'
import { ConsentProvider } from './Consent'

const AppProviders = ({children}) => {
  return <AnalyticsProvider>
    <ConsentProvider>
      <UserProvider>
        {children}
      </UserProvider>
    </ConsentProvider>
  </AnalyticsProvider>
  return <AnalyticsProvider>
    <ConsentProvider>
      <AuthProvider>
        <ThemeProvider>
          {children}
        </ThemeProvider>
      </AuthProvider>
    </ConsentProvider>
  </AnalyticsProvider>
}

export default AppProviders
