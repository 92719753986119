import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import emailIsValid from '../../components/EmailValidator'
import FormOverlay from '../../components/FormOverlay'
import { Analytics } from '../../context/Analytics'
import { User } from '../../context/User'
import './styles.css'

const axios = require('axios')

const Login = (props) => {
  const analytics = Analytics()
  const user = User()

  const [signedIn, setSignedIn] = useState(user.signedIn)
  const [captcha, setCaptcha] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [showEmailTooltip, setShowEmailTooltip] = useState(false)
  const [formTriggered, setFormTriggered] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const [formError, setFormError] = useState(false)

  const captchaOnChange = (e) => {
    setCaptcha(e.target.value)
  }

  const emailOnBlur = () => {
    if (emailValid && emailAddress !== '') {
      setShowEmailTooltip(false)
    } else {
      setShowEmailTooltip(true)
    }
  }

  const emailOnChange = (e) => {
    setEmailAddress(e.target.value)
    if (e.target.value.length < 7) {
      // Too short to be an email address
      return
    }
    if (emailIsValid(e.target.value)) {
      setEmailValid(true)
      setShowEmailTooltip(false)
      e.target.style.backgroundColor = '#fff'
      e.target.style.borderColor = '#3D7FEE'
    } else {
      setEmailValid(false)
      e.target.style.borderColor = '#dfdfdf'
    }
  }

  const formHandler = (e) => {
    e.preventDefault()
    // Check if required fields are filled out
    if (!emailValid) {
      setShowEmailTooltip(true)
      analytics.startSingleEvent({
        href: window.location.href,
        msg: 'form incomplete',
        type: 'error',
      })
      return
    }
    setFormTriggered(!formTriggered)
    analytics.startSingleEvent({
      href: window.location.href,
      type: 'form submit',
    })
    // Check captcha
    if (captcha !== '') {
      setFormError('Invalid captcha provided!')
      analytics.startSingleEvent({
        href: window.location.href,
        msg: 'Invalid captcha provided',
        type: 'error',
      })
      return
    }
    // Post to API
    try {
      //axios.post('https://api.ciso.dev/v1/auth', {
      axios.post('http://localhost:4000/v1/auth', {
        captcha: captcha,
        email: emailAddress,
      }).then((res) => {
        // Magic link request successful
        setFormSuccess(true)
      }).catch((err) => {
        // Error requesting magic link
        setFormError('Error connecting to API!')
        analytics.startSingleEvent({
          href: window.location.href,
          msg: `${err.message}`,
          type: 'error',
        })
      })
    } catch (err) {
      setFormError('An error occurred')
      analytics.startSingleEvent({
        href: window.location.href,
        msg: `${err.message}`,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    setSignedIn(user.signedIn)
  }, [user])

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    setSignedIn(user.signedIn)

    return () => {
      analytics.endEvent()
    }
  }, [])

  return (signedIn ? <Redirect to={props.location.from || "/"} /> :
    <div className="login-page fullpage">
      <div className="login">
        <div className="login-wrap pretty-fade">
          <div className="login-primary">
            <div className="login-header">
              <h1>Sign in</h1>
            </div>
            <div className="login-form-wrap">
              <form onSubmit={e => { e.preventDefault() }} className="login-form">
                <label className="email-wrap captcha">
                  <h3>What is this captcha?</h3>
                  <input onChange={captchaOnChange} type="email" className="email" placeholder="" />
                </label>
                <label className="email-wrap">
                  <h3>What is your email?</h3>
                  <p className="hidden">Enter your email address to request a magic link.</p>
                  <input onBlur={emailOnBlur} onChange={emailOnChange} type="email" className="email" placeholder="" required />
                  <div className={showEmailTooltip ? "emailTooltip" : "emailTooltip hidden"}>
                    <p>Enter a valid email address</p>
                  </div>
                </label>
                <p className="sub">Sign in to your account by requesting a magic sign-in link.</p>
                <div className="link-wrap">
                  <a href="#login" onClick={formHandler} title="Login to your account" className="login-button">Send my magic link</a>
                </div>
              </form>
            </div>
            <div className="login-footer">
            </div>
          </div>
          <div className="login-secondary">
            <h2>Don't have an account?</h2>
            <p>Sign up today to start your 30 day free trial.</p>
            <div className="link-wrap">
              <a href="https://ciso.dev/#subscribe" title="Sign up for security mentoring today" className="sign-up">Start my free trial</a>
            </div>
          </div>
        </div>
        <FormOverlay
          open={formTriggered}
          formError={formError}
          formSuccess={formSuccess}
          loadingMessageShort={"Loading"}
          loadingMessageLong={"Sending a magic link"}
          errorMessageTitle={"Oops, an error occurred!"}
          errorMessageSub={"Please refresh the page and try again."}
          errorMessageSubSmall={"We'll automatically refresh this page in"}
          successMessageTitle={"We've sent your magic link!"}
          successMessageSub={"Check your inbox for your magic link."}
          successMessageSubSmall={"This page will automatically refresh in"}
          shouldRefresh={formError}
          >
          <p className="success-message">
            <svg className="envelope" width="524" height="404" viewBox="0 0 524 404" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="envelope 1">
                <g clipPath="url(#clip0)">
                  <g id="layer1">
                    <g id="env-shadow">
                      <path id="rect833-7" opacity="0.1" d="M520.072 107.978H68.6432V402.264H520.072V107.978Z" fill="black" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <g id="env">
                      <path id="rect833" d="M522.072 103.978H70.6432V398.264H522.072V103.978Z" fill="white" stroke="#F2F3F4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                      <path id="path837" d="M116.73 362.609L379.26 159.633" stroke="#F2F3F4" strokeWidth="2" strokeLinecap="round"/>
                      <path id="path837-3" d="M476.403 362.931L213.455 159.634" stroke="#F2F3F4" strokeWidth="2" strokeLinecap="round"/>
                      <g id="path858">
                        <path d="M96.3575 136.835L274.929 258.264C274.929 258.264 294.929 272.55 317.786 258.264C340.643 243.978 489.215 141.121 494.929 133.978" fill="white"/>
                        <path d="M96.3575 136.835L274.929 258.264C274.929 258.264 294.929 272.55 317.786 258.264C340.643 243.978 489.215 141.121 494.929 133.978" stroke="#F2F3F4" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                      </g>
                    </g>
                    <g id="star-two-shadow">
                      <path id="path893-4" opacity="0.1" d="M37.2471 142.048C31.6219 144.376 27.066 131.652 21.4425 129.321C15.819 126.989 3.59612 132.756 1.26845 127.13C-1.05922 121.505 11.6647 116.949 13.9964 111.326C16.3281 105.702 10.5612 93.4795 16.1864 91.1518C21.8116 88.8241 26.3676 101.548 31.9911 103.88C37.6146 106.211 49.8374 100.445 52.1651 106.07C54.4928 111.695 41.7689 116.251 39.4372 121.874C37.1055 127.498 42.8723 139.721 37.2471 142.048V142.048Z" fill="black" stroke="black" strokeOpacity="0.995951" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path id="path893-5-1" opacity="0.1" d="M129.628 77.8303C125.917 79.3659 122.912 70.9717 119.202 69.4335C115.492 67.8952 107.428 71.6997 105.893 67.9887C104.357 64.2777 112.751 61.2721 114.29 57.5622C115.828 53.8523 112.023 45.7887 115.734 44.2531C119.445 42.7175 122.451 51.1116 126.161 52.6499C129.871 54.1882 137.934 50.3837 139.47 54.0947C141.006 57.8057 132.611 60.8113 131.073 64.5212C129.535 68.2311 133.339 76.2947 129.628 77.8303Z" fill="black" stroke="black" strokeOpacity="0.995951" strokeWidth="1.31943" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <g id="star-one-shadow">
                      <path id="path893-5-8-6" opacity="0.1" d="M47.8698 42.331C52.2189 44.1306 55.7413 34.2933 60.089 32.4905C64.4368 30.6878 73.8868 35.1464 75.6864 30.7973C77.486 26.4482 67.6486 22.9259 65.8459 18.5781C64.0432 14.2303 68.5018 4.78031 64.1527 2.98069C59.8036 1.18107 56.2812 11.0184 51.9335 12.8212C47.5857 14.6239 38.1357 10.1653 36.3361 14.5144C34.5365 18.8635 44.3739 22.3859 46.1766 26.7337C47.9793 31.0814 43.5207 40.5314 47.8698 42.331V42.331Z" fill="black" stroke="black" strokeOpacity="0.995951" strokeWidth="1.54629" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <g id="star-two">
                      <path id="path893" d="M37.2471 140.048C31.6219 142.376 27.066 129.652 21.4425 127.32C15.819 124.989 3.59612 130.756 1.26845 125.13C-1.05922 119.505 11.6647 114.949 13.9964 109.326C16.3281 103.702 10.5612 91.4794 16.1864 89.1517C21.8116 86.824 26.3676 99.548 31.9911 101.88C37.6146 104.211 49.8374 98.4445 52.1651 104.07C54.4928 109.695 41.7689 114.251 39.4372 119.874C37.1055 125.498 42.8723 137.721 37.2471 140.048Z" fill="#F0C808" stroke="#F0C808" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path id="path893-5" d="M129.628 75.8302C125.917 77.3658 122.912 68.9717 119.202 67.4335C115.492 65.8952 107.428 69.6997 105.893 65.9887C104.357 62.2776 112.751 59.2721 114.29 55.5621C115.828 51.8522 112.023 43.7887 115.734 42.2531C119.445 40.7175 122.451 49.1116 126.161 50.6499C129.871 52.1881 137.934 48.3837 139.47 52.0947C141.006 55.8057 132.611 58.8113 131.073 62.5212C129.535 66.2311 133.339 74.2947 129.628 75.8302Z" fill="#F0C808" stroke="#F0C808" strokeWidth="1.31943" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <g id="star-one">
                      <path id="path893-5-8" d="M47.8698 40.331C52.2189 42.1306 55.7413 32.2933 60.089 30.4905C64.4368 28.6878 73.8868 33.1464 75.6864 28.7973C77.486 24.4482 67.6487 20.9259 65.8459 16.5781C64.0432 12.2303 68.5018 2.78031 64.1527 0.980693C59.8036 -0.818925 56.2812 9.01844 51.9335 10.8212C47.5857 12.6239 38.1357 8.16533 36.3361 12.5144C34.5365 16.8635 44.3739 20.3858 46.1766 24.7336C47.9793 29.0814 43.5207 38.5314 47.8698 40.331V40.331Z" fill="#F0C808" stroke="#F0C808" strokeWidth="1.54629" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                  </g>
                </g>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="523.572" height="403.764" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </p>
        </FormOverlay>
      </div>
    </div>
  )
}

export default Login
