import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Analytics } from './Analytics'
import ConsentManager from '../components/Consent'

const ConsentContext = React.createContext()

const ConsentProvider = (props) => {
  const analytics = Analytics()

  const [cookies, setCookies, removeCookies] = useCookies(['Consent'])
  const [modalOpen, setModalOpen] = useState(false)
  const [noticeOpen, setNoticeOpen] = useState(true)

  const toggleModal = (e) => {
    e.preventDefault()
    setModalOpen(!modalOpen)
    analytics.startSingleEvent({
      href: window.location.href,
      type: 'cookie modal toggled',
    })
  }

  const toggleNotice = (e) => {
    e.preventDefault()
    setNoticeOpen(!noticeOpen)
    analytics.startSingleEvent({
      href: window.location.href,
      type: 'cookie notice toggled',
    })
  }

  const consentAcceptHandler = (e) => {
    e.preventDefault()
    let expiry = new Date()
    expiry.setFullYear(expiry.getFullYear() + 1)
    let options = {
      path: '/',
      expires: expiry,
      maxAge: (60*60*24*366),
    }
    if (process.env.NODE_ENV === 'production') {
      options.secure = true
      options.domain = `.${window.location.hostname}`
      options.sameSite = true
    }
    setCookies('Consent', {
      consent: true,
      timestamp: new Date().getTime()
    }, options)
    setNoticeOpen(false)
    analytics.startSingleEvent({
      href: window.location.href,
      type: 'consent accept',
    })
  }

  const consentDeclineHandler = (e) => {
    e.preventDefault()
    let expiry = new Date()
    expiry.setFullYear(expiry.getFullYear() + 1)
    let options = {
      path: '/',
      expires: expiry,
      maxAge: (60*60*24*366),
    }
    if (process.env.NODE_ENV === 'production') {
      options.secure = true
      options.domain = `.${window.location.hostname}`
      options.sameSite = true
    }
    setCookies('Consent', {
      consent: false,
      timestamp: new Date().getTime()
    }, options)
    setNoticeOpen(false)
    analytics.startSingleEvent({
      href: window.location.href,
      type: 'consent decline',
    })
  }

  useEffect(() => {
    setNoticeOpen(cookies.Consent ? false : true)
  }, [])

  return <ConsentContext.Provider value={{noticeOpen, modalOpen, toggleNotice, toggleModal, consentAcceptHandler, consentDeclineHandler}}>
    {props.children}
    <ConsentManager />
  </ConsentContext.Provider>
}

const Consent = () => {
  const context = React.useContext(ConsentContext)
  if (typeof context === 'undefined') {
    throw new Error('Consent must be used within a ConsentProvider')
  }
  return context
}

export { ConsentProvider, Consent }
