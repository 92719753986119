import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Analytics } from '../../context/Analytics'
import { User } from '../../context/User'
import './styles.css'

const Logout = (props) => {
  const analytics = Analytics()
  const user = User()

  useEffect(() => {
    analytics.startSingleEvent({
      href: window.location.href,
      type: 'logout',
    })
    user.logout()
  }, [])

  return <Redirect to="/login" />
}

export default Logout
