import React, { useState, useEffect, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { Analytics } from '../../context/Analytics'
import { User } from '../../context/User'
import './styles.css'

import getSubscription from '../../components/ParseSubscription'

const Account = (props) => {
  const analytics = Analytics()
  const user = User()

  const [signedIn, setSignedIn] = useState(user.signedIn || false)
  const [account, setAccount] = useState(user.account() || {})
  const [meta, setMeta] = useState(user.meta() || {})
  const [onboard, setOnboard] = useState(user.onboard || false)
  const [name, setName] = useState(user.name || '')
  const [linkedin, setLinkedin] = useState(user.linkedin || '')
  const [editing, setEditing] = useState(false)

  // Tooltip state
  const [showNameTooltip, setShowNameTooltip] = useState(false)
  const [showLinkedInTooltip, setShowLinkedInTooltip] = useState(false)

  const nameRef = useRef(null)
  const linkedinRef = useRef(null)

  const edit = (e) => {
    e.preventDefault()
    setEditing(!editing)
  }

  const nameOnChange = (e) => {
    setShowNameTooltip(false)
    setName(e.target.value)
  }

  const nameOnFocus = (e) => {
    e.target.style.backgroundColor = '#fff'
    e.target.style.borderColor = '#3D7FEE'
  }

  const nameOnBlur = (e) => {
    if (e.target.value.length > 0) {
      e.target.style.backgroundColor = '#fff'
      e.target.style.borderColor = '#3D7FEE'
    } else {
      setShowNameTooltip(true)
      e.target.style.borderColor = '#dfdfdf'
    }
  }

  const linkedinOnChange = (e) => {
    setShowLinkedInTooltip(false)
    setLinkedin(e.target.value)
  }

  const linkedinOnFocus = (e) => {
    e.target.style.backgroundColor = '#fff'
    e.target.style.borderColor = '#3D7FEE'
  }

  const linkedinOnBlur = (e) => {
    if (e.target.value.length > 0) {
      e.target.style.backgroundColor = '#fff'
      e.target.style.borderColor = '#3D7FEE'
    } else {
      setShowLinkedInTooltip(true)
      e.target.style.borderColor = '#dfdfdf'
    }
  }

  const updateHandler = (e) => {
    e.preventDefault()
    if (name === '') {
      nameRef.current.focus()
      return
    }
    if (linkedin === '') {
      linkedinRef.current.focus()
      return
    }
    const data = {
      name: name,
      linkedin: linkedin,
    }
    user.updateUserData(data, true)
    setEditing(false)
  }

  const cancelSubHandler = (e) => {
    e.preventDefault()
    user.cancelSubscription()
  }

  const billingHandler = (e) => {
    e.preventDefault()
    user.updateBilling()
  }

  useEffect(() => {
    if (editing) nameRef.current.focus()
  }, [editing])

  useEffect(() => {
    setSignedIn(user.signedIn)
    setAccount(user.account())
    setMeta(user.meta())
  }, [user])

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  return (
    (!signedIn)
      ? <Redirect to={{ pathname: '/login', from: '/settings' }} />
      : (!onboard ? <Redirect to="/setup" /> :
    <div className="account-page">
      <form onSubmit={e => { e.preventDefault() }} className={"content-card" + (editing ? " edit-mode" : "")}>
        <div className="content-card-header pretty-fade">
          <h1 className="pretty-fade-inner">Edit your personal info</h1>
          <p className="pretty-fade-inner">{ editing ? "Tell us more about yourself." : "Here's what we know about you." }</p>
        </div>
        <div className="content-card-section pretty-fade">
          <label className="name-wrap">
            <h2 className="pretty-fade-inner">What's your name?</h2>
            <p className="pretty-fade-inner">What should we call you?</p>
            { editing ? <input ref={nameRef} tabIndex="-1" onFocus={nameOnFocus} onBlur={nameOnBlur} onChange={nameOnChange} className="input-name text" type="text" defaultValue={name} required /> : <input className="input-name text" type="text" defaultValue={name} required disabled /> }
            { editing ?
              <div className={showNameTooltip ? "showTooltip" : "showTooltip hidden"}>
                <p>Enter your preferred name</p>
              </div>
              : <></> }
          </label>
        </div>
        <div className="content-card-section pretty-fade">
          <label className="linkedin-wrap">
            <h2 className="pretty-fade-inner">What's your LinkedIn URL?</h2>
            <p className="pretty-fade-inner">Share your profile with your mentor.</p>
            { editing ? <input ref={linkedinRef} onFocus={linkedinOnFocus} onBlur={linkedinOnBlur} onChange={linkedinOnChange} className="input-linkedin text" type="text" defaultValue={linkedin} placeholder="https://linkedin.com/in/..." required /> : <input onChange={linkedinOnChange} className="input-linkedin text" type="text" defaultValue={linkedin} placeholder="https://linkedin.com/in/..." required disabled /> }
            { editing ?
              <div className={showLinkedInTooltip ? "showTooltip" : "showTooltip hidden"}>
                <p>Enter your Linkedin profile URL</p>
              </div>
              : <></> }
          </label>
        </div>
        <div className="content-card-section pretty-fade">
          <div className="link-wrap">
            {(editing ? <a href="#next" onClick={updateHandler} title="Save changes to your account" className="submit-button">Save changes</a> : <a href="#edit" onClick={edit} title="Edit your profile" className="submit-button">Edit your profile</a>)}
          </div>
        </div>
      </form>
      <div className="content-card-container">
        <div className="content-card ancillary">
          <div className="content-card-header">
            <h1>Review your account</h1>
          </div>
          <div className="content-card-section">
            <h2>Your subscription</h2>
            <p><strong>Started:</strong> {(new Date(meta.createdAt * 1000).toString().split(' ').slice(0,4).join(' '))}</p>
            <p><strong>Email Address:</strong> {user.email}</p>
            <p><strong>Subscription:</strong> {(getSubscription({track: account.track, hours: account.hours}).plan)} @ {account.hours} hours</p>
            <p><strong>Due monthly:</strong> <span className="currency">$</span>{account.cost}</p>
          </div>
          <div className="content-card-section">
            <div className="link-wrap">
              <a href="#billing" onClick={billingHandler} title="Update your billing info" className="submit-button">Update payment details</a>
            </div>
          </div>
        </div>
      </div>
      <div className="content-card-container">
        <div className="content-card ancillary">
          <div className="content-card-header">
            <h1>Danger zone</h1>
          </div>
          <div className="content-card-section">
            <h2>Cancel your account</h2>
            <p>Are you sure you want to cancel your subscription?</p>
          </div>
          <div className="content-card-section">
            <div className="link-wrap">
              <a href="#cancel" onClick={cancelSubHandler} title="Cancel your subscription" className="submit-button danger">Cancel subscription</a>
            </div>
          </div>
        </div>
      </div>
    </div>)
  )
}

export default Account
