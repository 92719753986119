import React, { useState, useEffect, useRef } from 'react'
import { Redirect } from 'react-router-dom'
import { Analytics } from '../../context/Analytics'
import { User } from '../../context/User'
import './styles.css'

const AccountSetup = (props) => {
  const analytics = Analytics()
  const user = User()

  const [signedIn, setSignedIn] = useState(user.signedIn || false)
  const [onboard, setOnboard] = useState(user.onboard || false)
  const [name, setName] = useState(user.name || '')
  const [linkedin, setLinkedin] = useState(user.linkedin || '')

  // Tooltip state
  const [showNameTooltip, setShowNameTooltip] = useState(false)
  const [showLinkedInTooltip, setShowLinkedInTooltip] = useState(false)

  const nameRef = useRef(null)
  const linkedinRef = useRef(null)

  const nameOnChange = (e) => {
    setShowNameTooltip(false)
    setName(e.target.value)
  }

  const nameOnFocus = (e) => {
    e.target.style.backgroundColor = '#fff'
    e.target.style.borderColor = '#3D7FEE'
  }

  const nameOnBlur = (e) => {
    if (e.target.value.length > 0) {
      e.target.style.backgroundColor = '#fff'
      e.target.style.borderColor = '#3D7FEE !important'
    } else {
      setShowNameTooltip(true)
      e.target.style.borderColor = '#dfdfdf !important'
    }
  }

  const linkedinOnChange = (e) => {
    setShowLinkedInTooltip(false)
    setLinkedin(e.target.value)
  }

  const linkedinOnFocus = (e) => {
    e.target.style.backgroundColor = '#fff'
    e.target.style.borderColor = '#3D7FEE'
  }

  const linkedinOnBlur = (e) => {
    if (e.target.value.length > 0) {
      e.target.style.backgroundColor = '#fff'
      e.target.style.borderColor = '#3D7FEE !important'
    } else {
      setShowLinkedInTooltip(true)
      e.target.style.borderColor = '#dfdfdf !important'
    }
  }

  const onboardHandler = async (e) => {
    e.preventDefault()
    if (name === '') {
      nameRef.current.focus()
      return
    }
    if (linkedin === '') {
      linkedinRef.current.focus()
      return
    }
    const data = {
      name: name,
      linkedin: linkedin,
      onboard: true,
    }
    await user.updateUserData(data, true)
  }

  useEffect(() => {
    setSignedIn(user.signedIn)
    setOnboard(user.onboard)
  }, [user])

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  return (
    (!signedIn)
      ? <Redirect to={{ pathname: "/login", from : "/setup" }} />
      : (onboard ? <Redirect to="/settings" /> :
    <div className="account-page">
      <form onSubmit={e => { e.preventDefault() }} className={"content-card onboard-form"}>
        <div className="content-card-header pretty-fade">
          <h1 className="pretty-fade-inner">Step 1. Account Setup</h1>
          <p className="pretty-fade-inner">Tell us about yourself to get started.</p>
        </div>
        <div className="content-card-section pretty-fade">
          <label className="name-wrap">
            <h2 className="pretty-fade-inner">What's your name?</h2>
            <p className="pretty-fade-inner">What should we call you?</p>
            <input ref={nameRef} tabIndex="-1" onFocus={nameOnFocus} onBlur={nameOnBlur} onChange={nameOnChange} className="input-name text" type="text" defaultValue={name} required />
            <div className={showNameTooltip ? "showTooltip" : "showTooltip hidden"}>
              <p>Enter your preferred name</p>
            </div>
          </label>
        </div>
        <div className="content-card-section pretty-fade">
          <label className="linkedin-wrap">
            <h2 className="pretty-fade-inner">What's your LinkedIn URL?</h2>
            <p className="pretty-fade-inner">Share your profile with your mentor.</p>
            <input ref={linkedinRef} onFocus={linkedinOnFocus} onBlur={linkedinOnBlur} onChange={linkedinOnChange} className="input-linkedin text" type="text" defaultValue={linkedin} placeholder="https://linkedin.com/in/..." required />
            <div className={showLinkedInTooltip ? "showTooltip" : "showTooltip hidden"}>
              <p>Enter your Linkedin profile URL</p>
            </div>
          </label>
        </div>
        <div className="content-card-section pretty-fade">
          <div className="link-wrap">
            <a href="#next" onClick={onboardHandler} title="Continue setting up your account" className="submit-button">Next steps</a>
          </div>
        </div>
      </form>
    </div>)
  )
}

export default AccountSetup
