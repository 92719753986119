import React, { useState, useEffect } from 'react'
import { Cookies, useCookies } from 'react-cookie'

import AuthLoader from '../components/AuthLoader'

const axios = require('axios')
axios.defaults.withCredentials = true

const AuthContext = React.createContext()

const AuthProvider = (props) => {
  const [cookies, setCookies, removeCookies] = useCookies(['Token', 'Session'])
  const [isAuth, setIsAuth] = useState(false)
  const [loading, setLoading] = useState(true)

  const [createdAt, setCreatedAt] = useState(0)
  const [updatedAt, setUpdatedAt] = useState(0)
  const [consent, setConsent] = useState(0)
  const [current, setCurrent] = useState(true)
  const [onboard, setOnboard] = useState(true)
  const [track, setTrack] = useState(1)
  const [hours, setHours] = useState(1)
  const [corp, setCorp] = useState(false)
  const [lastLogin, setLastLogin] = useState({
    time: 0,
    ip: '',
  })
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [mentor, setMentor] = useState('')
  const [mentees, setMentees] = useState([])
  const [notes, setNotes] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [isMentor, setIsMentor] = useState(false)

  const updateUserData = (data) => {
    setEmail(data.email)
    setCreatedAt(data.createdAt)
    setUpdatedAt(data.updatedAt)
    setCurrent(data.current)
    setConsent(data.consent)
    setOnboard(data.onboard)
    setTrack(data.track)
    setHours(data.hours)
    setCorp(data.corp)
    setLastLogin(data.lastLogin)
    if (data.name) {
      setName(data.name)
    }
    if (data.linkedin) {
      setLinkedin(data.linkedin)
    }
    if (data.mentor) {
      setMentor(data.mentor)
    }
    if (data.mentees) {
      setMentees(data.mentees)
    }
    if (data.notes) {
      setNotes(data.notes)
    }
    if (data.isAdmin) {
      setIsAdmin(data.isAdmin)
    }
    if (data.isMentor) {
      setIsMentor(data.isMentor)
    }
  }

  useEffect(() => {
    localStorage.setItem('email', email)
  }, [email])

  useEffect(() => {
    localStorage.setItem('createdAt', createdAt)
  }, [createdAt])

  useEffect(() => {
    localStorage.setItem('updatedAt', updatedAt)
  }, [updatedAt])

  useEffect(() => {
    localStorage.setItem('current', current)
  }, [current])

  useEffect(() => {
    localStorage.setItem('consent', consent)
  }, [consent])

  useEffect(() => {
    localStorage.setItem('onboard', onboard)
  }, [onboard])

  useEffect(() => {
    localStorage.setItem('track', track)
  }, [track])

  useEffect(() => {
    localStorage.setItem('hours', hours)
  }, [hours])

  useEffect(() => {
    localStorage.setItem('corp', corp)
  }, [corp])

  useEffect(() => {
    localStorage.setItem('lastLogin', lastLogin)
  }, [lastLogin])

  useEffect(() => {
    localStorage.setItem('name', name)
  }, [name])

  useEffect(() => {
    localStorage.setItem('linkedin', linkedin)
  }, [linkedin])

  useEffect(() => {
    localStorage.setItem('mentor', mentor)
  }, [mentor])

  useEffect(() => {
    localStorage.setItem('mentees', mentees)
  }, [mentees])

  useEffect(() => {
    localStorage.setItem('notes', notes)
  }, [notes])

  useEffect(() => {
    localStorage.setItem('isAdmin', isAdmin)
  }, [isAdmin])

  useEffect(() => {
    localStorage.setItem('isMentor', isMentor)
  }, [isMentor])

  const login = async (code) => {
    if (code) {
      setLoading(true)
      axios.post('http://localhost:4000/v1/auth/verify', {
        code: code,
      }).then((res) => {
        // Store user data in browser
        updateUserData(res.data.data)
        // Set the session cookie with nonce
        let expiry = new Date()
        expiry.setDate(expiry.getDate() + 14)
        let options = {
          path: '/',
          expires: expiry,
          maxAge: (60*60*24*14),
        }
        if (process.env.NODE_ENV === 'production') {
          options.secure = true
          options.domain = `.${window.location.hostname}`
          options.sameSite = true
        }
        setCookies('Session', res.data.nonce, options)
        setIsAuth(true)
      }).catch((err) => {
        setIsAuth(false)
        throw err
      })
    } else if (cookies.Session) {
      setLoading(true)
      axios.get('http://localhost:4000/v1/auth/validate', {
        withCredentials: true,
      }).then((res) => {
        setIsAuth(true)
        setLoading(false)
      }).catch((err) => {
        setIsAuth(false)
        setLoading(false)
      })
    } else {
      setIsAuth(false)
      setLoading(false)
    }
  }

  const logout = () => {
    removeCookies('Session')
    setIsAuth(false)
    setLoading(false)
  }

  useEffect(() => {
    login()
  }, [])

  return <AuthContext.Provider value={{isAuth, login, logout, loading}}>
    {props.children}
    <AuthLoader />
  </AuthContext.Provider>
}

const Auth = () => {
  const context = React.useContext(AuthContext)
  if (typeof context === 'undefined') {
    throw new Error('Auth must be used within an AuthProvider')
  }
  return context
}

export { AuthProvider, Auth }
