const plans = {
  '1': {
    title: 'Student Track',
    hours: {
      '1': {
        price: 49,
      },
      '2': {
        price: 95,
      },
      '3': {
        price: 140,
      },
      '4': {
        price: 185,
      },
    },
  },
  '2': {
    title: 'Individual Contributor',
    hours: {
      '1': {
        price: 99,
      },
      '2': {
        price: 190,
      },
      '3': {
        price: 280,
      },
      '4': {
        price: 370,
      },
    },
  },
  '3': {
    title: 'Management Track',
    hours: {
      '1': {
        price: 149,
      },
      '2': {
        price: 285,
      },
      '3': {
        price: 420,
      },
      '4': {
        price: 555,
      },
    },
  },
  '4': {
    title: 'Executive Track',
    hours: {
      '1': {
        price: 249,
      },
      '2': {
        price: 475,
      },
      '3': {
        price: 700,
      },
      '4': {
        price: 925,
      },
    },
  },
}

const getSubscription = (plan) => {
  return {
    plan: plans[`${plan.track}`].title,
    cost: plans[`${plan.track}`].hours[`${plan.hours}`].price,
  }
}

export default getSubscription
