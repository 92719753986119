import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
//import { TransitionGroup, Transition } from 'react-transition-group'
//import { animPlay, animExit } from './components/LoadAnimations'
import { Helmet } from 'react-helmet'
import { User } from './context/User'
import Header from './components/Header'
import Footer from './components/Footer'
import AccountSetup from './pages/Setup'
import Account from './pages/Account'
import Home from './pages/Home'
import Login from './pages/Login'
import Logout from './pages/Logout'
import Verify from './pages/Verify'
import './App.css';

const App = (props) => {
  const user = User()

  const requireLogin = (indexState, replace) => {
    if (user.signedIn) {
      const dest = indexState.location.pathname
      throw new Error(JSON.stringify(indexState))
      replace({
        pathname: '/a-thing',
        search: '?dest=/sandwiches',
      })
    }
  }

  useEffect(() => {
  }, [])

  return (
    <BrowserRouter>
      <div className="App">
        <Helmet>
          <title>CISO.DEV - Level up your security career</title>
          <meta name="description" content="Security mentors for every step of your cyber security career." />
        </Helmet>
        <Header />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/verify" component={Verify} />
          <Route exact path="/setup" component={AccountSetup} />
          <Route exact path="/settings" component={Account} />
          <Route exact path="/" component={Home} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  )

  /*
  return (
    <BrowserRouter>
      <div className="App">
        <Helmet>
          <title>CISO.DEV - Level up your security career</title>
          <meta name="description" content="Security mentors for every step of your cyber security career." />
        </Helmet>
        <Header />
        <Route render={({ location }) => {
          const { pathname, key } = location
          return (
            <TransitionGroup component={null}>
              <Transition
                key={key}
                appear={true}
                onEnter={(node, appears) => animPlay(pathname, node, appears)}
                onExit={(node, appears) => animExit(node, appears)}
                timeout={{enter: 750, exit: 150}}>
                <Switch location={location}>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/logout" component={Logout} />
                  <Route exact path="/verify" component={Verify} />
                  <Route exact path="/" component={Account} />
                  <Route exact path="/settings" component={Home} />
                </Switch>
              </Transition>
            </TransitionGroup>
          )
        }}/>
        <Footer />
      </div>
    </BrowserRouter>
  )
  */
}

export default App
