import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import emailIsValid from '../../components/EmailValidator'
import { Analytics } from '../../context/Analytics'
import { User } from '../../context/User'
import './styles.css'

const Home = (props) => {
  const analytics = Analytics()
  const user = User()

  const [signedIn, setSignedIn] = useState(user.signedIn || false)
  const [onboard, setOnboard] = useState(user.onboard || false)
  const [account, setAccount] = useState(user.account() || {})
  const [meta, setMeta] = useState(user.meta() || {})

  useEffect(() => {
    setSignedIn(user.signedIn)
    setAccount(user.account())
    setMeta(user.meta())
  }, [user])

  useEffect(() => {
    window.scrollTo(0,0)

    analytics.startEvent({
      href: window.location.href,
      type: 'page',
    })

    return () => {
      analytics.endEvent()
    }
  }, [])

  return (
    (!signedIn)
      ? <Redirect to={{ pathname: "/login", from: "/" }} />
      : (!onboard
          ? <Redirect to="/setup" />
          : (((!meta.isMentor) || (account.mentor !== ""))
              ? <div className="mentor-page">
                  <div className="content-card">
                    <div className="content-card-header pretty-fade">
                      <h1>Hi there, {user.name}!</h1>
                      <p className="pretty-fade-inner">{account.welcome}</p>
                    </div>
                  </div>
                  <div className="content-card-container">
                    <div className="content-card ancillary">
                      {(account.mentor !== "")
                        ? <>
                            <div className="content-card-section">
                              <h2>Your mentoring profile</h2>
                              <p><strong>Here's your mentor:</strong> {account.mentor}, <a href="mailto://mentor@ciso.dev" title={`Reach out to ${account.mentor.split(' ')[0]} over email`}>mentor@ciso.dev</a>.</p>
                            </div>
                            {(account.notes.length > 0
                              ? <>
                                  <div className="content-card-section">
                                    <h2>Account resources</h2>
                                    <p>{account.notes}</p>
                                  </div>
                                </>
                              : <></>
                            )}
                          </>
                        : <div className="content-card-section">
                            <div className="disabled">
                              <h2>Hold tight, you don't have a mentor yet.</h2>
                              <p>We'll email you as soon as we find the right mentor for you.</p>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              : <Redirect to="/mentees" />
            )
        )
  )
}

export default Home
