import React, { useState, useEffect } from 'react'
import './styles.css'

const AuthLoader = (props) => {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(props.loading)
  }, [props])

  return <div className={loading ? "auth-loading-wrapper open" : "auth-loading-wrapper closed"}>
    <svg className="loading-spinner" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M26.6667 26.6667C26.6667 11.9387 38.6053 0 53.3333 0C68.0613 0 80 11.9387 80 26.6667C80 41.3947 68.0613 53.3333 53.3333 53.3333C53.3333 68.0613 41.3947 80 26.6667 80C11.9387 80 0 68.0613 0 53.3333C0 38.6053 11.9387 26.6667 26.6667 26.6667ZM53.3333 48C65.1147 48 74.6667 38.448 74.6667 26.6667C74.6667 14.8853 65.1147 5.33333 53.3333 5.33333C41.552 5.33333 32 14.8853 32 26.6667C32 38.448 41.552 48 53.3333 48Z" fill="#FFFFFF" fillOpacity="1"/>
    </svg>
  </div>
}

export default AuthLoader
